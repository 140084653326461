import React, { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import LazyLoad from "react-lazyload"

import SEO from "./../components/seo"
/* import FontGoudy from "../../static/fonts/goudymediaeval-400-regular.woff2" */
import Profile from "./../components/profile"
import svgarnold from "../assets/svgarnold"
import Footer from "../components/Footer"

import VideoBupon from "../assets/video-bupon.webm"
import VideoFilter from "../assets/video-filter.webm"
import VideoSmart from "../assets/video-smart.webm"
import VideoDiscord from "../assets/video-discord.webm"
import VideoSocial from "../assets/video-social.webm"
import svgtwitter from "../assets/svgtwitter"

function Card3D(ev, imgElRef) {
  let mouseX = ev.nativeEvent.offsetX
  let mouseY = ev.nativeEvent.offsetY

  let rotateY = map(mouseX, 0, 300, -25, 25) // 180
  let rotateX = map(mouseY, 0, 418, 25, -25) // 250
  let brightness = map(mouseY, 0, 418, 1.15, 0.85) // 250

  imgElRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
  imgElRef.current.style.filter = `brightness(${brightness})`
}

function map(val, minA, maxA, minB, maxB) {
  return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
}

const IndexPage = () => {
  // eq: options.name (gatsby-config)
  const data = useStaticQuery(graphql`
    query {
      subcards: allFile(
        filter: { sourceInstanceName: { eq: "subcardsFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const subRareMref = useRef(null)
  const subRareYref = useRef(null)
  const subUncommonYref = useRef(null)
  const subUncommonMref = useRef(null)

  return (
    <>
      {/*     <link
      rel="preload"
      as="font"
      href={FontGoudy}
      type="font/woff2"
      crossOrigin="anonymous"
    /> */}
      <SEO withoutBread={true} />
      <Profile />
      <PageLayout>
        {/*         <MyText
          as={"h1"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "none",
            "--text-shadow-hover": "none",
          }}
        >
          MTG Builders
        </MyText> */}
        <ST_svg
          style={{
            "--stroke": "none",
            "--width": "72px",
            "--height": "72px",
            "--fill": "#ccc",
            "--margin": "3rem",
          }}
        >
          {svgarnold}
        </ST_svg>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 11px 4px black",
          }}
        >
          MTG Builders is a community of players devoted to building the future
          of Magic
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Join now and brew with the most advanced
        </MyText>
        <MyText
          as={"h1"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "2rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          MTG Deck Builder
        </MyText>
        <a
          href="https://twitter.com/mtgbuilders/status/1387810703466278919"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ST_svg
            style={{
              "--stroke": "none",
              "--width": "48px",
              "--height": "48px",
              "--fill": "#ccc",
              "--margin": "3rem 3rem 0rem 3rem",
              "--filter": "drop-shadow(0px 4px 6px #2DE2E6)",
            }}
          >
            {svgtwitter}
          </ST_svg>
        </a>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "6rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          How does it work?
          <br />
          Read the twitter thread
          <br />
          or keep scrolling
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Smart Brew
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Drag cards wherever you want on the virtual table!
          <br />
          Not sure about a specific card? Drag it on the "Temporary" area.
          <br />
          Observe the stats instantly change based on your actions.
          <br />
          Use keyboard shortcuts to quickly move between Binder, Table, Filters
          and Details.
        </MyText>
        <LazyLoad height={720} offset={100}>
          <StyledVideo autoPlay loop muted playsInline controls>
            <source src={VideoSmart} type="video/webm" />
          </StyledVideo>
        </LazyLoad>
        <MyText
          as={"p"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Filter Everything
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Give me only Simic cards. With Escape.
          <br />
          Type? Hmm... Giant only - oh boy! <br />
          Every color now. <br />
          Can also be of type Creature, but cannot be Giant. Nah, keep Giant.{" "}
          <br />
          Don't filter for Escape, but keep it there, in case I change my mind.{" "}
          <br />
          You know what? I don't care about Escape.
          <br />
          Oh, I wonder if there are non-Creature Giant cards...
        </MyText>
        <LazyLoad height={720} offset={100}>
          <StyledVideo autoPlay loop muted playsInline controls>
            <source src={VideoFilter} type="video/webm" />
          </StyledVideo>
        </LazyLoad>
        <MyText
          as={"p"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Discord Bots
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          When a new deck is created, discord bots will post its URL in the
          just-brewed channel of the corresponding category.
          <br />
          Only interested in BO3 Modern decks? Unmute the just-brewed channel in
          the BO3-Modern category!
          <br />
          Wanna playtest a SMOrc deck? Write in the R channel in the
          BO1-Standard category!
        </MyText>
        <LazyLoad height={720} offset={100}>
          <StyledVideo playsInline autoPlay loop muted controls>
            <source src={VideoDiscord} type="video/webm" />
          </StyledVideo>
        </LazyLoad>
        <MyText
          as={"p"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Social
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Personalize your Profile page.
          <br />
          Add a deck to your Favorites.
          <br />
          Follow authors you esteem. Your Feed section will be made up of decks
          they create.
          <br />
          Authors won't know you're following them, nor how many followers they
          have.
        </MyText>
        <LazyLoad height={720} offset={100}>
          <StyledVideo playsInline autoPlay loop muted controls>
            <source src={VideoSocial} type="video/webm" />
          </StyledVideo>
        </LazyLoad>
        <MyText
          as={"p"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Build upon
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 6px 2px black",
          }}
        >
          Improve existing decks! Click on the Builder icon to import any deck
          into your Builder.
          <br />
          Take inspiration, and build something much more powerful.
        </MyText>
        <LazyLoad height={720} offset={100}>
          <StyledVideo autoPlay loop muted playsInline controls>
            <source src={VideoBupon} type="video/webm" />
          </StyledVideo>
        </LazyLoad>
        <Flex_Row>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subUncommonMref)
              }}
              onMouseLeave={ev => {
                subUncommonMref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subUncommonMref.current.style.filter = "brightness(1)"
              }}
              ref={subUncommonMref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subUncommonM"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"p"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Uncommon ~ Monthly
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $4.99/month
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 20 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subUncommonYref)
              }}
              onMouseLeave={ev => {
                subUncommonYref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subUncommonYref.current.style.filter = "brightness(1)"
              }}
              ref={subUncommonYref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subUncommonY"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"p"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Uncommon ~ Annual
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $19.99/year
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 20 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              +100 Coins
            </MyText>
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subRareMref)
              }}
              onMouseLeave={ev => {
                subRareMref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subRareMref.current.style.filter = "brightness(1)"
              }}
              ref={subRareMref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subRareM"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"p"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Rare ~ Monthly
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $9.99/month
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 60 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create private decks
            </MyText>
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subRareYref)
              }}
              onMouseLeave={ev => {
                subRareYref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subRareYref.current.style.filter = "brightness(1)"
              }}
              ref={subRareYref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subRareY"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"p"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Rare ~ Annual
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $39.99/year
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 60 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create private decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "4rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              +500 Coins
            </MyText>
          </SubBox>
        </Flex_Row>
        <Link to="/dashboard/subscribe/">
          <img
            width="228"
            height="44"
            src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png"
            alt="Check out with PayPal"
          />
        </Link>
        <Footer />
      </PageLayout>
      {/*     <SEO title="MioSito" /> */}
    </>
  )
}

export default IndexPage

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  /*   justify-content: center; */
  align-items: center;
`

const StyledVideo = styled.video`
  width: 100%;
  max-width: 720px;
  height: auto;
  box-shadow: 0px 0px 30px 20px #181818;
  border-radius: 5px;
  margin: 3rem 0rem;
  margin-bottom: 10rem;
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`

const Flex_Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 2rem;
`

const SubBox = styled.div`
  padding: 2rem 2rem 1rem;
  box-shadow: 0px 0px 15px 3px #181818;
  border-radius: 10px;
  margin: 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 15px 3px #ff974c;
  }
`

const WrapperGatsbyImg = styled.div`
  cursor: default;
  margin: 4px; //  4px
  width: 30rem;
  /*   height: 41.8rem; */
  perspective: 600px;
  transition: transform 0.25s ease-out;
  transform: scale(1);
  margin-bottom: 3rem;
  /*   margin-bottom: 3rem; */

  /*  &:hover {
    z-index: 10;
    transform: scale(1.3);
  } */

  /*   &:before {
    // pseudoelement non fa confondere Card3D
    content: "${props => props.userAlias}";
    text-align: center;
    position: absolute;
    top: ${props => (props.myProfileCardShape === "moldtk" ? "76%" : "82%")};
    z-index: 1;
    width: 100%;
  } */
`

const GatsbyImgProfile = styled(Img)`
  width: 30rem;
  /*   height: 41.8rem; */
  transition: transform 0.25s ease-out;
  border-radius: 1.5rem;

  &:hover {
    z-index: 10;
    transform: scale(1.05);
  }
`

const ST_svg = styled.div`
  margin: var(--margin);

  svg {
    fill: var(--fill);
    stroke: var(--stroke);
    width: var(--width);
    height: var(--height);
  }

  svg > path:nth-child(2) {
    fill: var(--fill);
  }

  &:hover {
    svg {
      filter: var(--filter);
      opacity: 1;
    }
  }
`
